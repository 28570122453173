/**
 * Method for finding hideheader footer params and show hide them based on value
 * @returns boolean for header param
 */
const hideHeaderFooterBasedOnParams = () => {
  const urlSearchParams = new window.URLSearchParams(window.location.search)
  if(!urlSearchParams || urlSearchParams?.size === 0) return false
  const hideHeaderParam = urlSearchParams.get('hideHeader') === 'true'
  const hideFooterParam = urlSearchParams.get('hideFooter') === 'true'
  const hideHeaderFooterParam = urlSearchParams.get('hideHeaderFooter') === 'true'
  if(hideFooterParam || hideHeaderFooterParam) {
    document.getElementById('footer-content')?.classList.add('d-none')
  }
  if(hideHeaderParam || hideHeaderFooterParam) {
    document.getElementById('header-content')?.classList.add('d-none')
  }
}

export default hideHeaderFooterBasedOnParams